import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import React from 'react';
import Parser from 'html-react-parser';

const CommonRenderProp = props => {
	return props.render(props.commonData);
};

CommonRenderProp.propTypes = {
	render: PropTypes.func.isRequired,
	commonData: PropTypes.shape({
		labels: PropTypes.shape({}).isRequired,
		sitemap: PropTypes.shape({}).isRequired,
		prefs: PropTypes.shape({}).isRequired,
		flags: PropTypes.shape({}).isRequired
	}).isRequired
};

const labelAsHTML = (label, Tag = React.Fragment) => {
	const html = Parser(label);
	return <Tag>{html}</Tag>;
};

const mapStateToProps = state => {
	return {
		commonData: {
			labels: {
				has: key => state.labels[key] !== undefined,
				get: key => state.labels[key] || key,
				getKeys: () => Object.keys(state.labels),
				getAll: () => state.labels,
				getHTML: (key, Tag) => labelAsHTML(state.labels[key] || key, Tag)
			},
			sitemap: {
				has: alias => state.sitemap[alias] !== undefined,
				get: alias => state.sitemap[alias] || null,
				getAll: () => state.sitemap
			},
			prefs: state.prefs,
			flags: state.flags
		}
	};
};

export const Common = connect(
	mapStateToProps,
	null, // mapDispatchToProps
	null, // mergeProps
	{ pure: false }
)(CommonRenderProp);

export const createStore = state => {
	return {
		subscribe: () => {},
		dispatch: () => {},
		getState: () => state
	};
};

export const CommonTester = props => {
	const { initialState, flags, labels, sitemap, prefs, render } = props;
	const store = createStore({
		...initialState,
		flags,
		labels,
		sitemap,
		prefs
	});

	return <Provider store={store}>{render()}</Provider>;
};

CommonTester.propTypes = {
	render: PropTypes.func.isRequired,
	labels: PropTypes.shape({}),
	sitemap: PropTypes.shape({}),
	prefs: PropTypes.shape({}),
	flags: PropTypes.shape({}),
	initialState: PropTypes.shape({})
};

CommonTester.defaultProps = {
	labels: {},
	sitemap: {},
	prefs: {},
	flags: {},
	initialState: {}
};
